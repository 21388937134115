<template>
  <div class="address">
<!--    <detail-view :dataArray="itemData"/>-->
    <detail-view />
  </div>
</template>

<script>
  import DetailView from "@/components/global/DetailView";
  // import AddressAPI from '@/api/address'
  // import { assignServices, assignMeters, getSumPay, getAddressTitle } from "@/utils";

  export default {
    props: {
      item: {
        type: Object
      },
      schema: {
          type: String,
          required: true
      },
      secret_id: {
          type: String,
          required: true
      }
    },
    name: 'AuthenticatedUserView',
    data(){
      return {
        dataItem: {},
      }
    },
    methods: {
      // async getAddress() {
      //   try {
      //     let {data} = await AddressAPI.addressView(this.schema, this.secret_id);
      //     data.services = assignServices(data.services);
      //     data.meters = assignMeters(data.meters, data.services);
      //     data.sum_pay = getSumPay(data);
      //     data.title = getAddressTitle(data.person);
      //     data.schema = this.schema;
      //     data.secret_id = this.secret_id;
      //     this.dataItem = data;
      //   } catch (e) {
      //       this.$snackbar("Помилка одержання адреси", 'error')
      //   }
      // },
    },
    computed: {
      // itemData() {
      //   if (!this.item) {
      //     return this.dataItem;
      //   } else {
      //     return this.item;
      //   }
      // },
    },
    created() {
      // if (!this.item) {
      //   this.getAddress()
      // }
    },
    components: {
      DetailView,
    },
  }
</script>

<style scoped lang="scss">
</style>
